#diaspora-page{
  display: flex;
  flex-direction: row;
}

#diaspora-left{
  display: flex;
  flex-direction: column;
  padding-top: 20%;
  padding-left: 1%;
  width: 30vw; 
  position: fixed;
  bottom: 0; 
  left:0;
}

.left-text{
  margin-bottom: 20px;
}

#diaspora-right{
  padding-top: 2%;
  padding-bottom: 1%;
  display: flex;
  flex-direction: column;
  margin-left: 35%;
  width: 100%;
}

.diaspora-image-title{
  font-size: 20px;
  margin-bottom: 10px;
}

img{
  max-width: 900px;
}

.image-item{
  margin-bottom: 10px;
}

@media (max-width: 990px){
  #diaspora-page{
    display: flex;
    flex-direction: column;
    padding-top: 30%;
    justify-content: center;
    align-items: center;
  }

  #diaspora-right{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-content: center;
    align-items: center;
  }

  #diaspora-left{
    display: flex;
    flex-direction: column;
    padding-top: 2%;
    position: relative;
    width: 90%;
  }

  img{
    max-width: 350px;
  }
}