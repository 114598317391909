#info-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 10% 50% 10% 50%;
    font-size: 18px;
    font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#info-item-0{
    font-size: 15px;
}

#info {
    display: flex;
    flex-direction: column;
    width: 30vw;
}

span {
    color: #b0a82c;}

.info-item{
    margin-top: 2%;
}

#contact {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: black;
    margin-top: 8%;
}

.contact-item{
    margin: 0% 2% 0% 2%;
    border-bottom: black solid 1px;
}

.contact-item-0{
    margin-right: 2%;
    border-bottom: black solid 1px;
}

ul li a{
    border-bottom: solid black 1px;
}

li{
    margin-top: 2px;
}


@media (max-width:990px){
    #info-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30%;
        color: black;
        font-size: 18px;
    }
    
    #info {
        width: 18em;
        padding-left: 40%;
        text-align: start;
    }
    
    .info-item{
        margin-top: 4%;
    }
    
    #contact {
        text-decoration: none;
        color: black;
        margin-top: 15%;
        text-align: start;
    }
    
    .contact-item{
        margin: 0% 2% 0% 2%;
    }
    
    .contact-item-0{
        margin-right: 2%;
    }
    
    a{
        color: black;
        text-decoration: none;
    }
}