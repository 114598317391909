html {
  background-color: #ffffffc1;
  margin: 0;
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: blue;
}

a:hover{
  background-color: #DFFF00;
}